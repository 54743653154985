import { Component, Vue } from "vue-property-decorator";
import EditAgentComponent from '../Component/EditAgent.component.vue';

@Component({
    components: {
        EditAgentComponent
    }
})
default class EditAgent extends Vue {
    private role = this.$store.state.profile.roles[0] 
}
export default EditAgent