












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import EditAgentComponent from "./EditAgent.component";

@Component
export default class EditAgent extends Mixins(EditAgentComponent) {}

